import React from 'react'
import { Link } from "gatsby"
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Helmet from 'react-helmet'
import { Container, Row, Col, Button, Form } from 'reactstrap'
import styled from 'styled-components'
import Scroll from 'react-scroll'
import HiringSection from '../../components/Hiring'
import ContactForm from '../../components/ContactForm'
import WhatWeDoNav from '../../components/WhatWeDoNav'
import Faq from '../../components/Faq'
import Layout from '../../components/Layout'

export default class extends React.Component {

  render() {
    return (
      <Layout>
      <div className="what-we-do">
        <Helmet
          title="Software Consulting | Bishop Software"
          meta={[
            { name: 'description', content: 'Our software implementation and licensing consultants will work with you to understand your needs and develop a plan of action to help you meet your business goals. Let\'s talk.' },
            { name: 'keywords', content: 'software,development,web,design,mobile,consultancy,consulting,website,application,react,ios,android' }
          ]}
        />
        <section style={{paddingTop: '75px', display: 'block', backgroundImage: 'linear-gradient(132deg, #2c3532 0%, #568c6c 73%, #78c498 90%, #edf5e0 100%)', color: '#fafafa'}}>
          <Header dropdownGradient='linear-gradient(#7ac99a 0px, #389583 100%)' section="what-we-do" />
          <Container className="text-center" style={{paddingTop: '1.875rem'}}>
            <div style={{margin: '55px 0 0 0'}}>
              <h1 style={{color: '#FFCB9B', fontSize: '40px', fontWeight: 300}}>Software Consulting</h1>
              <h3 style={{fontSize: '18px', marginTop: '35px', fontWeight: 400, lineHeight: '24px'}}>We can help your team create mission-critical software that scales new heights.</h3>
              <div style={{marginTop: '35px', paddingBottom: '35px'}}>
                <Scroll.Link to="contact" spy={true} smooth={true} className="round btn btn-primary"><i className="fas fa-arrow-down"></i></Scroll.Link>
              </div>
            </div>
          </Container>
        </section>
      <Scroll.Element name="contact" />

        <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(176deg, #ddd 0, #fff 30%, #fff 90%, #f1f1f1 100%)'}}>
      <Container>
        <Row>
          <Col md="8" xs="12">
            <p style={{color: '#555', fontSize: '22px'}}>It is easy to become overwhelmed by all of the choices and options available when it comes to your organization’s applications. Our software implementation and licensing consultants will work with you to understand your needs and develop a plan of action to help you meet your business goals.</p>
            <p style={{color: '#555', fontSize: '18px'}}>As a results-oriented software consulting firm, Bishop Software believes that your company should have the resources you need to succeed in a challenging business climate. From custom application development to full application lifecycle management, our team will design and implement software solutions tailored to your organizational requirements.</p>
          </Col>
          <Col md="4" xs="12" className="d-none d-md-block">

            <WhatWeDoNav title="Related Services" titleBackground="#dbe5d0" titleColor="#333" color="#288D91" links={[
              { link: '/custom-software-development', title: 'Custom Software Development'},
              { link: '/web-mobile-development', title: 'Web & Mobile Development'},
              { link: '/user-experience', title: 'User Experience & Design'}
            ]} />

          </Col>
        </Row>
      </Container>
    </section>

    <section style={{backgroundImage: 'linear-gradient(179deg, #2c2f39 2%, #363944 14%, #32353d 100%)', color: '#ccc', padding: '70px 0'}}>
      <Container className="text-center">
        <h1 style={{color: '#fff', marginBottom: '25px'}}>How We Can Help</h1>
          <Faq title="Requirements Gathering" iconColorOpen="#54886A" iconColor="#a7d3b9" titleColor="#eee" titleColorOpen="#ccc" color="#eee" open={true}>

<p>Requirements gathering is about building a real understanding of our client, their business, their customers and their goals. And we'll use every technique in the book to gain that understanding. We research the market, analyse competitors, interview members of staff, set up workshops, carry out surveys, and even speak directly to users. </p>

<p>Our consultants have to get under the skin of your business, which is where stakeholder workshops prove so valuable. They help us answer questions such as:</p>
<ul>
  <li>What are the overall business goals?</li>
  <li>How will success be measured?</li>
  <li>Who are your key user groups?</li>
</ul>

<p>Then it’s on to your users. We love to speak to them directly, learning about their motivations, expectations, devices, usage patterns - anything that will help us understand what they need and want from your digital products and services. We can also review your existing data, to uncover patterns and trends that users themselves may not be aware of.</p>

<p>This information gives us a valuable head start going into the project. We’ll know what needs to be done, and in what order. And you won’t just get the software you want - <strong>you’ll get the software you need to achieve your goals.</strong></p>
          </Faq>
          <Faq title="Code Reviews" iconColorOpen="#54886A" iconColor="#a7d3b9" titleColor="#eee" titleColorOpen="#ccc" color="#eee">
<p>During a code review we’ll look at every aspect of your code, including:</p>
<ul>
  <li>How well it adheres to standards and good practices</li>
  <li>How effectively it’s supported</li>
  <li>How easy it is to maintain</li>
  <li>How secure and scalable it is</li>
  <li>How it performs in terms of speed, reliability and security</li>
</ul>
<p>We’ll then highlight the key facts and figures in a report compiled just for you, that can be used as an initial benchmark against which you can measure progress over time. We’ll also provide clear, practical recommendations for improvement, prioritised so that you can direct your efforts towards where they’ll deliver the greatest possible benefit, as quickly as possible.</p>
<p>Is your development project experiencing time and budget overruns and veering from your initial scope? Or are you just not seeing the results you expect? Our team can help you redress the situation. Experienced in joining projects at any stage, we can take control of developments that are failing whatever the cause - for example lack of discipline, loss of key staff or insufficient expertise - creating a custom program of work that will improve your project’s performance and ensure the highest levels of quality across all deliverables.</p>
          </Faq>
          <Faq title="Software Design" iconColorOpen="#54886A" iconColor="#a7d3b9" titleColor="#eee" titleColorOpen="#ccc" color="#eee">
<p>Before crafting your ideal software solution, we first make sure we have an in-depth appreciation of your aims and objectives. And we find that, to build up this knowledge, you can’t beat one-on-one conversations. So we’ll interview key members of your team, hold stakeholder workshops and briefing sessions, and even survey your users to find out what they want to achieve from your digital products. </p>
<p>When we’re fully up to speed, our coders, engineers and architects can begin translating your requirements into a useful and usable format. Assessment and review are the watchwords throughout this whole process - we’ll continually test different technologies to validate their effectiveness in meeting your goals. We’ll even build representative, interactive prototypes to determine with absolute certainty that the solution we propose is suitable.</p>
<p>The result? Software designs that you can trust implicitly. That your developers can follow at every stage. And that guarantee you a finished solution more effective, more flexible and more advanced than any off-the-shelf product on the market.</p>
          </Faq>
          <Faq title="Software Development" iconColorOpen="#54886A" iconColor="#a7d3b9" titleColor="#eee" titleColorOpen="#ccc" color="#eee">
<p>We love to code. As our team has evolved over the past 12 years, as we’ve changed faces and offices, the one constant has been the presence of a robust software development practice.</p>
<p>Check out our software development pages for more information:</p>
<ul>
  <li><Link to="/custom-software-development" style={{color: '#C0F3D5', textDecoration: 'underline'}}>Custom Software Development</Link></li>
  <li><Link to="/web-mobile-development" style={{color: '#C0F3D5', textDecoration: 'underline'}}>Web &amp; Mobile Development</Link></li>
</ul>

          </Faq>
          <Faq title="Software Testing" iconColorOpen="#54886A" iconColor="#a7d3b9" titleColor="#eee" titleColorOpen="#ccc" color="#eee">
<p>Many systems managers are fearful of their software failing, all too aware of the effect it can have on the profitability of their business.</p>
<p>Our expert consultants can help you develop software testing strategies and quality assurance procedures that will help to minimise risk, while lowering stress levels at the same time. We use our knowledge to identify any potential vulnerabilities in your process, as well as opportunities for efficiency or cost savings. And then we define a solution that’s unique to you.</p>
<p>We’ll detail:</p>
<ul>
  <li>The approaches, tools and workflows that will give you the coverage you need.</li>
  <li>Recommendations on reporting so that you always have the latest, most relevant information.</li>
  <li>Guidance on creating the user stories and acceptance criteria that will confirm your strategy’s working.</li>
  <li>RCN prototype iPhone and iPad</li>
</ul>
<p>Why run the risk of expensive downtime and performance issues? Prevention is better than cure - <strong>give your software some protection.</strong></p>
          </Faq>
          <Faq title="IT Consulting" iconColorOpen="#54886A" iconColor="#a7d3b9" titleColor="#eee" titleColorOpen="#ccc" color="#eee" last={true}>
<p>Drawing on experience built up over many years working across a range of industries, our team of IT consultants will provide you with advice on maximizing the agility, capability and capacity of your systems.</p>
<p>We deliver at a practical level too - offering expert support on the ground in the form of environment audits, systems consolidation, server maintenance and more.</p>
<p>And we always keep one eye on the road ahead, to inform you of any emerging trends, tools and techniques that could be of benefit to your organization.</p>
<ul>
  <li>Need a robust procurement, disaster recovery or business community plan? We’ll deliver clear guidelines supported by in-depth research and analysis.</li>
  <li>Considering moving some of your services to the cloud? We’ll create a plan for rollout that will get you where you need to go.</li>
  <li>Not sure which hosting option is best for you? We’ll define an appropriate solution based on your current and future needs.</li>
</ul>
          </Faq>
      </Container>
    </section>

    <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(176deg, #ddd 0, #fff 30%, #fff 90%, #f1f1f1 100%)'}}>
      <ContactForm />
    </section>

        <HiringSection />

        <Footer section="what-we-do" />
      </div>
  </Layout>
    )
  }
}
  